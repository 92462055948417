@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.form-input, .form-textarea {
		@apply w-full mx-auto px-3 rounded-md outline-none shadow border border-gray-300 focus:border-purple-200 text-xs text-gray-900 focus:bg-white disabled:opacity-50 invalid:border-red-500 invalid:text-red-600
		focus:invalid:border-red-500 focus:invalid:ring-red-500;
	}

	.form-checkbox {
		@apply h-4 w-4 cursor-pointer rounded outline-none border border-gray-300 focus:border-purple-200 text-purple-800;
	}

	.form-radio {
		@apply h-4 w-4 cursor-pointer border-gray-300 focus:ring-purple-700 text-purple-800;
	}

	.form-label {
		@apply block mb-1 text-gray-600 text-xs;
	}

	.form-checkbox-label {
		@apply ml-2 text-gray-600 text-sm font-medium;
	}
	
	.form-label.required:after {
		content:" *";
		@apply text-red-600;
	}

	.form-error {
		@apply mt-1 text-xs text-red-500;
	}

	.form-hint {
		@apply mt-1 text-xs text-gray-500;
	}

	.form-datepicker {
		@apply flex form-input py-0.5;
	}

	.form-datepicker input {
		@apply p-0 w-full outline-none;
	}

	.form-datepicker div.toggle-suffix {
		@apply flex justify-end p-0;
	}

	.ng-select.ng-invalid {
		@apply border border-red-500 text-red-600 focus:border-red-500 focus:ring-red-500 rounded;
	}

	.btn {
		@apply py-2 px-4 leading-normal text-sm;
	}
	
	.btn-primary {
		@apply py-2 px-4 leading-normal text-sm text-white bg-purple-600 border-purple-600 rounded outline-none hover:bg-purple-800 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 capitalize;
	}
	
	.btn-danger {
		@apply py-2 px-4 leading-normal text-sm text-white bg-red-600 border-red-600 rounded outline-none hover:bg-red-800 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 capitalize;
	}

	.btn-outline {
		@apply py-2 px-4 leading-normal text-sm text-gray-700 border-gray-700 transition duration-150 ease-in-out bg-white border rounded-md hover:text-purple-500 focus:outline-none focus:shadow-purple-300 focus:border-purple-300 active:text-purple-700 disabled:cursor-not-allowed disabled:opacity-50;
	}

	.btn-quick-links {
		@apply bg-white font-semibold inline-flex items-center px-4 py-2 rounded text-gray-700;
	}

	.btns-group-right {
		@apply flex flex-col md:flex-row justify-end gap-4;
	}

	.link-primary {
		@apply cursor-pointer text-purple-600 hover:text-purple-800;
	}

	.link-danger {
		@apply cursor-pointer text-red-600 hover:text-red-800;
	}

	.quick-links-wrapper {
		@apply inline-block relative text-sm;
	}
	
	.card {
		@apply p-6 bg-white shadow rounded-md text-sm;
	}

	.card-select {
		@apply border-2 border-transparent hover:border-purple-600 px-6 rounded-md cursor-pointer;
	}

	.tbl-wrapper {
		@apply border shadow rounded-md;
	}

	.tbl-overflow {
		@apply overflow-x-auto lg:overflow-x-visible h-full;
	}
	
	.tbl {
		@apply min-w-full divide-y divide-gray-200;
	}
	
	.tbl-header {
		@apply p-4 font-medium leading-4 tracking-wider text-left text-xs text-gray-500 capitalize bg-gray-50;
	}
	
	.tbl-row {
		@apply p-4 whitespace-nowrap text-xs text-gray-800;
	}

	.tbl-actions {
		@apply p-4 flex items-center space-x-2 justify-end text-xs whitespace-nowrap;
	}

	.actions-primary {
		@apply px-1 cursor-pointer text-purple-600 hover:text-purple-900;
	}

	.actions-danger {
		@apply px-1 cursor-pointer text-red-600 hover:text-red-900;
	}

	.main-nav-link {
		@apply block py-2 mt-2 text-lg text-gray-500 rounded-sm md:text-xs hover:text-purple-700 hover:bg-gray-200 focus:outline-none;
	}

	.main-nav-link-active {
		@apply font-bold bg-gray-200 text-purple-700 border-b-4 border-purple-600 shadow-lg;
	}
	
	nav.tabs {
		@apply sticky flex border-b-2 border-gray-200;
	}
	
	nav.tabs a {
		min-width: 160px;
		@apply py-2 px-8 capitalize font-medium text-center text-purple-500 hover:text-purple-700 focus:outline-none;
	}
	
	nav.tabs a.active {
		@apply text-white bg-purple-800 rounded-t-md;
	}
	
	nav.vertical-tabs {
		@apply sticky;
	}
	
	nav.vertical-tabs a {
		@apply my-4 px-2 font-medium block text-purple-500 hover:text-purple-700 focus:outline-none capitalize;
	}
	
	nav.vertical-tabs a.active {
		@apply text-purple-800 border-l-2 border-purple-800;
	}

	.dropdown-links-parent {
		@apply absolute bg-white invisible group-hover:visible right-0 rounded-lg shadow-lg text-gray-700 text-left w-40 z-10;
	}

	.dropdown-link {
		@apply block py-2 px-4 rounded-t whitespace-nowrap cursor-pointer hover:bg-gray-100 hover:font-normal space-x-2;
	}

	.grid-1-2 {
		@apply grid grid-cols-1 md:grid-cols-2 gap-2;
	}

	.grid-1-3 {
		@apply grid grid-cols-1 md:grid-cols-3 gap-4;
	}

	.grid-1-2-3 {
		@apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2;
	}

	.grid-1-2-4 {
		@apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2;
	}

	.grid-1-3-5 {
		@apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2;
	}

	.grid-1-3-6 {
		@apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2;
	}

	.details-title {
		@apply font-medium leading-6 text-gray-900 text-lg;
	}

	.details-subtitle {
		@apply mt-1 text-gray-600 text-sm;
	}

	.details-card {
		@apply shadow sm:rounded-md sm:overflow-hidden;
	}

	.details-card-padding {
		@apply px-4 py-5 sm:p-6 space-y-6 bg-white;
	}

	.hidden-tw {
		@apply hidden;
	}

	.modal-wrapper {
    @apply flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto;
  }

  .modal-inner-wrapper {
    @apply relative my-6 mx-auto w-auto max-w-xl;
  }

  .modal-content {
    @apply relative flex flex-col w-full border-0 rounded-lg shadow-lg bg-white outline-none focus:outline-none;
  }

  .modal-header {
    @apply p-4 flex items-start justify-between border-b border-solid border-purple-200 rounded-t;
  }

  .modal-body {
    @apply relative my-2 py-6 px-4 flex-auto;
  }

  .modal-footer {
    @apply p-4 flex items-center justify-end border-t border-solid border-purple-200 rounded-b gap-2;
  }

	.modal-target {
		@apply fixed inset-0 z-40 bg-black opacity-25;
	}

	.cancel-link {
		@apply text-sm text-red-600 cursor-pointer;
	}
}
