/* You can add global styles to this file, and also import other style files */

@import '../../../node_modules/@ngneat/hot-toast/src/styles/styles.css';
@import url("assets/css/custom.css");
@import url("tailwind.css");
@import 'sweetalert2/dist/sweetalert2.css';

@font-face {
	font-family: 'Libre Franklin';
	src: url('./assets/fonts/LibreFranklin-VariableFont_wght.ttf');
}

.form-datepicker button {
	height: auto;
	width: auto;
}
