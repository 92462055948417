@use '@angular/material' as mat;
@import "~@ng-select/ng-select/themes/default.theme.css";

$custom-typography: mat.define-legacy-typography-config($font-family: '"Libre Franklin", sans-serif;');
$custom-primary: mat.define-palette(mat.$deep-purple-palette);
$custom-accent: mat.define-palette(mat.$deep-purple-palette, 300);
$custom-warn: mat.define-palette(mat.$red-palette);
$custom-info: mat.define-palette(mat.$purple-palette, 400);
$custom-highlight: mat.define-palette(mat.$blue-gray-palette, 50);
$custom-theme: mat.define-light-theme($custom-primary, $custom-accent, $custom-warn);

// Export theme colors
$primary: mat.get-color-from-palette($custom-primary);
$accent: mat.get-color-from-palette($custom-accent);
$warn: mat.get-color-from-palette($custom-warn);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes($custom-theme);

a.mat-list-item.active {
    color: #fff !important;
    background: linear-gradient(14deg, mat.get-color-from-palette($custom-primary) 0%, mat.get-color-from-palette($custom-primary)) !important;
    -webkit-box-shadow: 1px 1px 5px 0 mat.get-color-from-palette($custom-primary) !important;
    box-shadow: 1px 1px 20px 0 mat.get-color-from-palette($custom-primary);
    border-radius: 4px;
}

.main-menu-items:hover {
    font-weight: bold !important;
    color: mat.get-color-from-palette($custom-primary) !important;
}

a.mat-list-item.active:hover {
    color: mat.get-color-from-palette($custom-primary) !important;
    background: #fff !important;
}

.mat-tab-label-active {
    color: #fff !important;
    background-color: mat.get-color-from-palette($custom-primary) !important;
    opacity: 1 !important;
    border-radius: 4px;
}

.icon-holder {
    background-color: mat.get-color-from-palette($custom-primary) !important;
}

.mat-tab-link:not(.mat-tab-label-active):focus {
    // color: #fff !important;
    background: none !important;
    border-radius: 4px;
}

::ng-deep.mat-tab-label.mat-tab-label-active {
    opacity: 1;
}

.theme-border-color {
    border-color: mat.get-color-from-palette($custom-primary) !important;
}

.mat-background-primary {
    background-color: mat.get-color-from-palette($custom-primary);
}

.mat-background-accent {
    background-color: mat.get-color-from-palette($custom-accent);
}

.mat-background-info {
    background-color: mat.get-color-from-palette($custom-info);
}

.mat-higlight {
    background-color: mat.get-color-from-palette($custom-highlight);
}

.mat-background-warn {
    background-color: mat.get-color-from-palette($custom-warn);
}

a {
    color: mat.get-color-from-palette($custom-primary);
}

footer a,
.highlighted a {
    color: mat.get-color-from-palette($custom-primary) !important;
}

// .toolbar-notifications {
//     background-color: mat-color($custom-primary) !important;
// }
